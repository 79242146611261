<template>
  <div class="content">
    <div class="title">Ошибка</div>
    <div class="description">{{ description }}</div>
  </div>
</template>
<script setup lang="ts">
import {defineProps, toRefs} from "vue";

const props = defineProps<{
  description: string
}>();

const { description } = toRefs(props);
</script>
<style scoped>
.content {
  line-height: 18px;
  padding: 25px 15px;
  max-width: 90%;
}
.title {
  font-size: 28px;
  font-weight: 700;
  line-height: 30px;
  color: #000000;
}

.description {
  margin-top: 8px;
  font-size: 17px;
  font-weight: 400;
  color: #8E8E93;
}
</style>