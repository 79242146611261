<template>
  <a class="container" v-if="isActive" :href="link" target="_blank">
    <div class="content">
      <div class="image" :style="'background-image: url(' + image + ')'"></div>
      <div class="text-content">
        <div class="title">{{ title }}</div>
        <div class="additional-info">
          <div class="tip">{{ tip }}</div>
          <div class="link">{{ displayableLink }}</div>
        </div>
      </div>
    </div>
    <svg class="arrow" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g id="arrow-outward">
        <path id="Icon" d="M16 8.4L7.10005 17.3C6.91672 17.4833 6.68338 17.575 6.40005 17.575C6.11672 17.575 5.88338 17.4833 5.70005 17.3C5.51672 17.1167 5.42505 16.8833 5.42505 16.6C5.42505 16.3167 5.51672 16.0833 5.70005 15.9L14.6 7H7.00005C6.71672 7 6.47922 6.90417 6.28755 6.7125C6.09588 6.52083 6.00005 6.28333 6.00005 6C6.00005 5.71667 6.09588 5.47917 6.28755 5.2875C6.47922 5.09583 6.71672 5 7.00005 5H17C17.2834 5 17.5209 5.09583 17.7125 5.2875C17.9042 5.47917 18 5.71667 18 6V16C18 16.2833 17.9042 16.5208 17.7125 16.7125C17.5209 16.9042 17.2834 17 17 17C16.7167 17 16.4792 16.9042 16.2875 16.7125C16.0959 16.5208 16 16.2833 16 16V8.4Z"/>
      </g>
    </svg>
  </a>
</template>

<script setup lang="ts">
import {defineProps, toRefs} from "vue";

const props = defineProps<{
  title: string,
  tip: string,
  link: string,
  image: string,
  isActive: boolean,
}>();

const {title, tip, link, image, isActive} = toRefs(props);

const getDomain = (url: string) => (url.split("/"))[2];
const displayableLink = getDomain(link.value);
</script>

<style scoped lang="scss">
@import "@/assets/styles/theme-colors";

@include themify($themes) {
  .container:not(:last-child) {
    border-bottom: 0.5px solid themed("separator");
  }
  .title {
    color: themed("text");
  }
  .tip {
    color: themed("accent-black");
    background-color: themed("accent-yellow");
  }
  .link {
    color: themed("hint");
  }
  .arrow {
    fill: themed("separator");
  }
}
.container {
  position: relative;
  display: flex;
  align-items: center;
  outline: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  padding: 3.85vw 0;
  min-height: 0;
}
.content {
  padding: 0 0;
  flex: 1;
  display: flex;
  align-items: center;
}
.image {
  width: 11vw;
  height: 11vw;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 2.5vw;
  margin-right: 2.7vw;
}
.text-content {
  flex: 1;
}
.title {
  font-size: 4vw;
  font-weight: normal;
  margin-bottom: 3vw;
  max-width: 90%;
}
.additional-info {
  margin-top: 0;
  display: flex;
  align-items: center;
}
.tip {
  font-size: 3.2vw;
  font-weight: normal;
  border-radius: 2vw;
  padding: 1.2vw 1.8vw;
  margin-right: 4.1vw;
}
.link {
  font-weight: normal;
  font-size: 3.8vw;
}
.arrow {
  position: relative;
  padding-bottom: 0.2vh;
  right: 2vw;
  width: 6vw;
  height: 6vw;
}
</style>
