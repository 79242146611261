<template>
  <div :class="theme +'-theme'">

    <TabHeaderComponent @tabSelected="selectTab" :tabs="tabs"/>
    <TabContentComponent v-for="(tab, index) in tabs" :key="index" :brand="tab"
                         :goods="goods[tab]" :advertises="advertises"
                         :active-tab="tabs[activeTab]"
                         :class="getContentClass(tab)"/>
    <div class="background"></div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/styles/theme-colors";

@include themify($themes) {
  .background {
    background: themed("background-secondary");
  }
}

.background {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.slide-in-right {
  animation: slideInRight 0.2s ease-in-out;
}

.slide-in-left {
  animation: slideInLeft 0.2s ease-in-out;
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
<script setup lang="ts">
import {getCurrentInstance, onActivated, onDeactivated, onMounted, onUnmounted, Ref, ref} from 'vue';
import TabHeaderComponent from "@/components/home/tab/TabHeaderComponent.vue";
import TabContentComponent from "@/components/home/tab/TabContentComponent.vue";
import {useWebApp, useWebAppTheme} from 'vue-tg'
import {AdvertiseObject} from "@/interfaces/advertises/AdvertiseInterface";
import {GoodsObject} from "@/interfaces/goods/GoodsInterface";

onMounted(() => {
  activeTab.value = parseInt(localStorage.getItem("tab") || "0");
})

onUnmounted(() => {
  localStorage.setItem("tab", activeTab.value.toString());
})

const theme = useWebAppTheme().colorScheme;

const advertises: Ref<AdvertiseObject[]> = ref(getCurrentInstance()?.appContext.config.globalProperties.advertises);
const goods: Ref<GoodsObject> = ref(getCurrentInstance()?.appContext.config.globalProperties.goods);


const tabs: Ref<Array<string>> = ref([]);
for (const key in goods.value) {
  tabs.value.push(key)
}

const activeTab: Ref<number> = ref(0)
const prevTab = ref(0);

const selectTab = (tab: number) => {
  prevTab.value = activeTab.value;
  activeTab.value = tab;
}
const getContentClass = (tab: string) => {
  return {
    'slide-in-right': tabs.value.indexOf(tab) > prevTab.value,
    'slide-in-left': tabs.value.indexOf(tab) < prevTab.value,
  };
};
</script>
