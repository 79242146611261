<template>
  <div class="tabs" ref="container">
    <div
        v-for="(tab, index) in tabs"
        :key="index"
        @click="userActivateTab(index)"
        :class="{ 'tab': true, 'active': activeTab === index }"
        ref="tabsRef"
    >
      {{ tab }}
    </div>
    <div class="underline" :style="{ transform: `translateX(${underlineTranslation}px)`, width: `${underlineWidth}px` }"></div>
  </div>
</template>

<script setup lang="ts">
import {
  ref,
  onMounted,
  defineEmits,
  defineProps,
  toRefs,
  onUpdated,
  nextTick,
  onBeforeUpdate,
  computed,
  onBeforeMount
} from 'vue';
import {Analytics} from "@/analytics/Analytics";
import {getCurrentInstance} from "vue";
import {setTransport} from "@amplitude/analytics-browser";
const analytics: Analytics = getCurrentInstance()?.appContext.config.globalProperties.analytics;

const emit = defineEmits(["tabSelected"]);
const props = defineProps<{
  tabs: Array<string>
}>();
const { tabs } = toRefs(props);

const underlineWidth = ref(0);
const activeTab = ref(0);
const tabsRef = ref<HTMLDivElement[]>([]);
const underlineTranslation = ref(0);

const container = ref<HTMLDivElement | null>(null);

onBeforeMount(() => {
  activeTab.value = parseInt(localStorage.getItem("tab") || "0");
})
const userActivateTab = (index: number) => {
  activateTab(index)
  analytics.sendEvent("Tab", {"Name": tabs.value[index]})
}
const activateTab = (index: number) => {
  activeTab.value = index;
  const tabElement = tabsRef.value[index];
  if (tabElement) {
    underlineWidth.value = tabElement.clientWidth;
    underlineTranslation.value = tabElement.offsetLeft;
  }

  emit("tabSelected", index)
};

onMounted(() => {

  activateTab(activeTab.value);
  setTimeout(() => activateTab(activeTab.value), 150)
  setTimeout(() => activateTab(activeTab.value), 200)
  setTimeout(() => activateTab(activeTab.value), 400)
  setTimeout(() => activateTab(activeTab.value), 600)
  setTimeout(() => activateTab(activeTab.value), 1200)
  setTimeout(() => activateTab(activeTab.value), 1400)
  setTimeout(() => activateTab(activeTab.value), 1600)

  scrollingEvent();
});
function scrollingEvent() {
  const scrollContainer = container.value;

  if (scrollContainer) {
    scrollContainer.addEventListener('wheel', function (e) {
      e.preventDefault();
      scrollContainer.scrollLeft += e.deltaY;
    });
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/theme-colors";

@include themify($themes) {
  .tabs {
    background-color: themed("background-main");
    border-bottom: 0.5px solid themed("separator");
  }
  .tab {
    color: themed("hint");
  }
  .active {
    color: themed("button");
  }
  .underline {
    background-color: themed("button");
  }
}
.tabs {
  padding-left: 4.3vw;
  position: fixed;
  width: 100%;
  z-index: 100;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;

  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.tab {
  display: inline-block;
  font-family: SF Pro, sans-serif;
  font-size: 4vw;
  font-weight: 500;
  text-align: center;
  padding: 6vw 0 3.5vw;
  cursor: pointer;
  transition: color 0.3s ease;
  outline: none;

  &:not(:last-child) {
    margin-right: 5.2vw
  }

  &:nth-last-child(2) {
    margin-right: 8.6vw
  }
  user-select: none;
  -webkit-tap-highlight-color: transparent;

}
.active {
  outline: none;
}

.underline {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0.8vw;
  border-radius: 0.5vw 0.5vw 0 0;
  transition: transform 0.3s ease, width 0.3s ease;
}

</style>
