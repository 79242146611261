<template>
  <MainButton text="Назад" @click="router.push('/') && useWebAppHapticFeedback().impactOccurred('soft')" />
  <div :class="`wrapper ${theme}-theme`">
    <div class="barcode-background" :style="{ height: containerHeight}">      </div>
    <div :class="`island island-${theme} islandTop`">
      <BarcodeComponent :class="{ 'barcodeContainer': true, 'fixed-container': isFixed }" :code="barcode" ref="container"/>
      <div v-if="isFixed" :style="{ height: containerHeight}"></div>
      <div class="content">
        <div :class="`title title-${theme}`">{{ title }}</div>
        <div class="description">{{ description }}</div>
      </div>
    </div>
    <div :class="`island island-${theme} islandBottom`">
      <div class="imageContainer" v-if="image.length">
        <img class="image" :src="image" />
      </div>
    </div>
    <div class="background"></div>
  </div>
</template>

<script setup lang="ts">
import {BackButton, MainButton, useWebAppHapticFeedback, useWebAppTheme, useWebAppViewport} from "vue-tg"
import {
  defineProps,
  ref,
  computed,
  toRefs,
  getCurrentInstance,
  onBeforeMount,
  Ref,
  onUnmounted,
  onMounted,
  nextTick, Component
} from "vue";
import BarcodeComponent from "@/components/good/BarcodeComponent.vue";
import router from "@/router";
import {GoodsObject} from "@/interfaces/goods/GoodsInterface";
import {GoodObject} from "@/interfaces/goods/GoodInterface";

useWebAppViewport().expand();
const theme = useWebAppTheme().colorScheme;

const props = defineProps<{
  brand: string
  id: number
}>();

const isFixed = ref(false);
const container = ref<typeof BarcodeComponent | null>(null);

const {brand, id} = toRefs(props);
const goods: Ref<GoodsObject> = ref(getCurrentInstance()?.appContext.config.globalProperties.goods);
const currentGood: Ref<GoodObject> = ref(goods.value[brand.value][id.value]);

const title = ref(currentGood.value.barcodeName);
const description = ref(`${currentGood.value.brand} ${currentGood.value.productLine}`);
const barcode = ref(currentGood.value.barcode);
const image = computed(() => currentGood.value.image);

const containerHeight = ref("");

const handleScroll = () => {
  if (container.value) {
    const barcode = container.value.barcodeContainer;

    isFixed.value = true;
    containerHeight.value = isFixed.value ? `${barcode.offsetHeight}px` : '0'
  }
};

onMounted(() => {
  handleScroll();
});
</script>

<style scoped lang="scss">
@import "@/assets/styles/theme-colors";

@include themify($themes) {
  .background {
    background: themed("background-secondary");
  }
  .barcode-background {
    background: themed("background-main");
  }
  .wrapper {
    background: themed("background-secondary");
  }
  .title {
    color: themed("text")
  }
  .description {
    color: themed("accent-gray")
  }
  .tip {
    color: themed("accent-gray")
  }
}

.background {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.barcodeContainer {
  margin-left: -16px;
  margin-right: -16px;

}
.barcode-background {
  width: 100%;
}
.fixed-container, .barcode-background {
  position: fixed;
  width: 100%;
  z-index: 90;
  overflow-x: auto;

  //transition: height 0.3s ease-out;
  //top: 0;
}

.wrapper {
  display: grid;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr;
}

.island {
  width: 100%;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  background-color: rgb(255, 255, 255);

  &-dark {
    background: themed("background-main");
  }
}

.islandTop {
  border-radius: 0 0 10px 10px;
}

.islandBottom {
  border-radius: 10px 10px 0 0;
}

.content {
  line-height: 18px;
  max-width: 90%;
}

.title {
  font-size: 28px;
  font-weight: bold;
  line-height: 33px;

  &-dark {
    margin-top: 16px;
  }
}

.description {
  margin-top: 8px;
  font-size: 20px;
  font-weight: 400;

  margin-bottom: 8px;
}

.tip {
  margin-top: 18px;

  line-height: 18px;
  font-size: 13px;
  font-weight: 400;

  max-width: 75%;
  padding-bottom: 10px;
}

.imageContainer {
  display: flex;
  background-color: rgba(239, 239, 244, 1);
  border-radius: 4px;
  overflow: hidden;
}

.image {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
}


</style>