<template>
  <div :class="$style.barcode" ref="barcodeContainer">
    <svg id="barcode"></svg>
  </div>
</template>
<script setup lang="ts">
import {defineProps, onMounted, ref, toRefs, watch} from "vue";
import { useWebAppTheme } from 'vue-tg'

const theme = useWebAppTheme().colorScheme;

const props = defineProps<{
  code: string
}>();

const {code} = toRefs(props);
const barcodeContainer = ref<HTMLElement | null>(null);

// eslint-disable-next-line no-undef
defineExpose({
  barcodeContainer,
});

import JsBarcode from "jsbarcode";

const generateBarcode = () => {
  JsBarcode("#barcode", code.value, {
    format: "code128",
    displayValue: false,
    width: 4,
    height: 135,
    marginTop: 16,
    marginBottom: 16,
    marginLeft: 40,
    marginRight: 40,
    background: "#fff",
    lineColor: "#000"
  });
}

onMounted(() => {
  generateBarcode();
});

watch(theme, generateBarcode);
</script>

<style module lang="scss">
.barcode {
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 12px;

  svg {
    width: 100%;
    height: 100%;
  }
}
</style>