<template>
  <div class="list" v-if="isActiveTab">
    <template v-for="(good, index) in goods" :key="index">
      <StandardCellComponent :brand="brand"
                             :title="good.barcodeName"
                             :description="good.productLine"
                             :id="goods.map((e) => e.barcode).indexOf(good.barcode)"
                             @click="analytics.sendEvent('Barcode',
                             {
                               'Brand': brand,
                               'Name': good.barcodeName,
                               'Product Line': good.productLine,
                               'Number': good.barcode
                             })"/>
      <AdvertiseCellComponent v-if="isAnyAd"
                              :isActive="advertisePosition === index"
                              :title="advertise.text"
                              :image="advertise.image"
                              :link="advertise.url"
                              :tip="advertise.label"
                              @click="analytics.sendEvent('Adv', {})"/>
    </template>
  </div>
</template>

<script setup lang="ts">
import AdvertiseCellComponent from "@/components/home/cell/AdvertiseCellComponent.vue";
import StandardCellComponent from "@/components/home/cell/StandardCellComponent.vue";
import {computed, defineProps, getCurrentInstance, ref, toRefs} from "vue";
import {AdvertiseObject} from "@/interfaces/advertises/AdvertiseInterface";
import {GoodObject} from "@/interfaces/goods/GoodInterface";
import {Analytics} from "@/analytics/Analytics";

const props = defineProps<{
  goods: GoodObject[],
  advertises: AdvertiseObject[],
  brand: string,
  activeTab: string
}>();

const {brand, goods, activeTab, advertises} = toRefs(props);

const analytics: Analytics = getCurrentInstance()?.appContext.config.globalProperties.analytics;
const generateRandomNumber = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const isActiveTab = computed(() => {
  return brand.value === activeTab.value
});

const isAnyAd = ref<boolean>(advertises.value.length != 0);
const advertise = ref<AdvertiseObject>();
const advertisePosition = ref<number>();

if (isAnyAd.value) {
  advertise.value = advertises.value[generateRandomNumber(0, advertises.value.length - 1)];
  advertisePosition.value = generateRandomNumber(0, goods.value.length - 1);
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/theme-colors";

@include themify($themes) {
  .list {
    background-color: themed("background-main");
  }
}

.list {
  margin: 0;
  padding-top: 14.9vw;
  padding-left: 4.3vw;
  box-sizing: border-box;
}
</style>