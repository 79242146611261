<template>
  <div :class="'container ' + theme + '-theme'">
    <div class="lds-ellipsis" :style="'top: ' + viewportHeight * 0.5 + 'px'">
      <div class="lds-child"></div>
      <div class="lds-child"></div>
      <div class="lds-child"></div>
      <div class="lds-child"></div>
    </div>
    <div class="content"></div>
  </div>

</template>
<script setup lang="ts">
import {useWebAppTheme, useWebAppViewport} from "vue-tg";

const theme = useWebAppTheme().colorScheme;
const viewportHeight = useWebAppViewport().viewportStableHeight;
</script>

<style scoped lang="scss">
@import "@/assets/styles/theme-colors";

@include themify($themes){
  .lds-child {
    background: themed("text");
  }
}
.content {
  content: "";
  font-family: 'SF Pro', sans-serif;
  font-weight: 500;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.lds-ellipsis {
  width: 12vw;
  height: 4vw;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lds-ellipsis div {
  position: absolute;
  width: 2vw;
  height: 2vw;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 2vw;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 2vw;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 6vw;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 10vw;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(4vw, 0);
  }
}

</style>
