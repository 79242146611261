import {Analytics} from "@/analytics/Analytics";
import * as amplitude from "@amplitude/analytics-browser"

export class AmplitudeAnalytics extends Analytics {
    private platform = amplitude;
    private identifyEvent: amplitude.Identify = new this.platform.Identify();
    private app_options = {platform: "Web app", app_version: process.env.VUE_APP_VERSION, version_name: process.env.VUE_APP_VERSION , language: ""};

    constructor(token: string) {
        super();
        this.platform.init(token, {
            defaultTracking: {
                pageViews: false,
                attribution: false,
            },
        });
    }

    public setIdentifyField(key: string, value: string | null) {
        this.identifyEvent.set(key, value || "None");

        this.platform.identify(this.identifyEvent);
    }

    public setUser(id: string) {
        this.platform.setUserId(id);
    }
    public setLanguage(language: string) {
        this.app_options.language = language;
    }

    public sendEvent(event: string, properties: object | undefined) {
        if (this.isPaused) return;

        this.platform.track(event, properties || {}, this.app_options);
    }
    public pause() {
        this.isPaused = true;
    }
}